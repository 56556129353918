<template>
  <div class="tw-p-layout-2 tw-w-full tw-bg-white">
    <transition name="fade" mode="out-in">
      <div v-if="isSearch" key="search" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <no-results-illustration class="tw-w-20 tw-h-20 tw-mb-5" />
        <app-text type="div" weight="medium" size="sm" class="tw-text-jb-ink tw-mb-space-1">
          No Results Found
        </app-text>
        <app-text type="div" weight="medium" size="sm" class="tw-text-jb-grey-700 tw-text-center">
          Please refine your search query to see {{ label }}.
        </app-text>
      </div>
      <div v-else key="empty" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <empty-state-illustration-svg :id="_uid" class="tw-w-20 tw-h-20 tw-mb-5" />
        <app-text type="div" weight="medium" size="sm" class="tw-text-jb-ink tw-mb-space-1">
          Nothing to Show
        </app-text>
      </div>
    </transition>
  </div>
</template>

<script>
import NoResultsIllustration from '@/assets/svg/no-results-illustration.svg';
import EmptyStateIllustrationSvg from '@/assets/EmptyStateIllustrationSvg';

import AppText from '@/components/AppText.vue';

export default {
  name: 'EmptyOrgSettingsState',
  components: { NoResultsIllustration, EmptyStateIllustrationSvg, AppText },
  props: {
    label: {
      type: String,
      default: 'results'
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  }
};
</script>
