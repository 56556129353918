<template>
  <app-modal
    class="remove-user-modal"
    :loading="loading"
    v-bind="$attrs"
    :destroy-on-close="true"
    :show-close="true"
    title="Confirmation Required"
    v-on="$listeners"
    @confirm="removeMember">
    <template #message>
      <app-text weight="medium" class="tw-mb-space-1">
        Are you sure you want to remove this member?
      </app-text>
      <div class="tw-mb-space-2 tw-mt-space-4">
        <app-text weight="medium" class="tw-text-jb-red-dark tw-mt-space-1">
          This action cannot be undone
        </app-text>
      </div>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/AppModal';
import AppText from '@/components/AppText';

export default {
  name: 'RemoveMemberModal',
  components: { AppModal, AppText },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    removeMember() {
      this.$emit('delete-member');
    }
  }
};
</script>
