<template>
  <div class="organization-members">
    <app-card :hover="false" class="owner-card tw-p-space-4 tw-mb-space-4 tw-bg-white tw-rounded">
      <template #header>
        <template v-if="ownerData.role">
          <div class="tw-mr-space-3 tw-relative">
            <app-profile-picture class="tw-bg-jb-indigo-darker tw-w-layout-4 tw-h-layout-4" :full-name="ownerData.first_name + ' ' + ownerData.last_name" />
            <star-icon
              class="tw-text-jb-yellow tw-w-space-5 tw-h-space-5 tw-left-8 tw-bottom-space-4 tw-absolute" />
          </div>
          <div>
            <app-heading class="tw-text-jb-ink tw-mb-0 tw-text-base tw-font-semibold">
              {{ ownerData.first_name }}  {{ ownerData.last_name }}
            </app-heading>
            <div class="tw-flex">
              <app-text type="div" weight="medium" class="tw-text-jb-grey-700 tw-flex tw-items-center tw-text-sm">
                {{ ownerData.role.name }}
                <circle-divider-icon v-if="$can($permissions.TRANSFER_ORG_OWNERSHIP)" class="tw-w-space-2 tw-h-space-2 tw-mx-space-1 tw-text-jb-grey-100 tw-hidden" />
              </app-text>
              <!-- TODO: Display when Transfer Ownership is available (and circle-divider) -->
              <app-link
                v-if="$can($permissions.TRANSFER_ORG_OWNERSHIP)"
                class="tw-text-jb-indigo tw-hidden tw-text-sm tw-font-medium"
                :class="{['!tw-text-jb-grey-300 !tw-cursor-not-allowed !tw-no-underline']: isTransferOwnershipButtonDisabled }"
                :disabled="isTransferOwnershipButtonDisabled"
                @click="handleTransferOwnership">
                Transfer Ownership
              </app-link>
            </div>
            <app-text weight="medium" class="tw-text-jb-grey-400 tw-text-sm">
              {{ ownerData.email }}
            </app-text>
          </div>
        </template>
        <div v-else-if="loading" class="tw-flex tw-items-start">
          <skeleton-box class="tw-h-layout-4 tw-mr-space-2 tw-w-layout-4 tw-m-0 tw-rounded-full" />
          <div class="tw-flex tw-flex-col tw-items-start">
            <skeleton-box class="tw-h-space-2 tw-mb-space-2 tw-mt-space-1 tw-w-20 tw-m-0" />
            <skeleton-box class="tw-h-space-2 tw-mb-space-2 tw-w-14 tw-m-0" />
            <skeleton-box class="tw-h-space-2 tw-w-24 tw-m-0" />
          </div>
        </div>
      </template>
    </app-card>
    <div class="tw-mb-space-4 tw-p-space-4 tw-bg-jb-indigo-lighter xs:tw-flex-row xs:tw-items-end tw-flex tw-flex-col tw-items-start tw-justify-between tw-rounded">
      <div class="xs:tw-w-auto tw-flex-grow tw-w-full">
        <app-text type="div" class="tw-text-jb-grey-700 tw-mb-space-1 tw-text-xs tw-font-medium">
          Members
        </app-text>
        <app-input v-model="searchValue" clearable placeholder="Search" class="tw-w-full">
          <template #prefix>
            <search-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400" />
          </template>
        </app-input>
      </div>

      <app-button v-if="$can($permissions.INVITE_USER_TO_ORG)" class="tw-bg-jb-indigo hover:tw-bg-jb-indigo-dark xs:tw-w-auto xs:tw-mt-0 tw-mt-space-4 xs:tw-ml-layout-3 tw-w-full tw-ml-0 tw-font-medium" @click="isNewUserModalOpen = !isNewUserModalOpen">
        <div class="tw-w-max-content tw-flex tw-items-center">
          <plus-icon class="tw-w-space-3 tw-h-space-3 tw-mr-space-1" />
          <app-text size="sm" weight="medium" class="tw-text-white">
            Add New Member
          </app-text>
        </div>
      </app-button>
    </div>
    <div>
      <template v-if="!loading && orgMembers.length">
        <app-card
          v-for="(member, index) in orgMembers"
          :key="index"
          :hover="false"
          class="member-list tw-py-space-2 tw-px-space-4 sm:tw-flex sm:tw-items-center sm:tw-justify-between tw-border-jb-grey-50 last:tw-border-b-0 tw-bg-white tw-border-0 tw-border-b tw-border-solid">
          <template #header>
            <div v-if="member.user_org_status === 'pending'" class="tw-bg-jb-indigo-darker tw-w-9 tw-h-9 tw-min-w-9 tw-mr-space-2 tw-flex tw-items-center tw-justify-center tw-text-white tw-rounded-full">
              <profile-icon class="profile-icon tw-w-6" />
            </div>
            <app-profile-picture v-else class="tw-w-9 tw-h-9 tw-bg-jb-indigo-darker tw-mr-space-2" :full-name="member.first_name + ' ' + member.last_name" />

            <div class="tw-flex tw-justify-between tw-w-full">
              <div class="tw-flex tw-flex-col tw-justify-center">
                <div v-if="member.user_org_status === 'pending'" class="tw-flex">
                  <pending-icon />
                  <app-text class="tw-text-jb-grey-700 xs:tw-text-sm tw-ml-space-1 tw-font-extralight tw-text-xs tw-italic">
                    Pending User
                  </app-text>
                </div>
                <app-text v-else class="tw-text-jb-ink xs:tw-text-sm tw-text-xs tw-font-medium">
                  {{ member.first_name }} {{ member.last_name }}
                </app-text>

                <app-text weight="medium" class="tw-text-jb-grey-400 xs:tw-text-sm tw-text-xs">
                  {{ member.email }}
                </app-text>
              </div>
              <portal-target v-if="!smUp" class="tw-mr-space-2" :name="`member-dropdown-${index}`" />
            </div>
          </template>
          <template>
            <app-select v-model="member.role.name" option-value-key="name" disabled :data="availableRoles">
              <template slot="option" slot-scope="row">
                <div class="tw-flex tw-flex-col">
                  <app-text class="tw-text-jb-ink tw-text-sm tw-font-medium">
                    {{ row.item.name }}
                  </app-text>
                  <app-text class="tw-text-jb-grey-700 tw-text-xs tw-font-medium">
                    {{ row.item.info }}
                  </app-text>
                </div>
              </template>
            </app-select>
          </template>
          <template #footer>
            <portal-target v-if="smUp" :name="`member-dropdown-${index}`" />
          </template>
          <portal :to="`member-dropdown-${index}`">
            <el-popover
              v-if="$can($permissions.MODIFY_USERS_ON_PARTNERSHIPS) || (!isCurrentUser(member) && $can($permissions.DELETE_USER_FROM_ORGANIZATION))"
              v-model="member.showManagePartnership"
              popper-class="org-members-permissons-popper"
              placement="bottom-end"
              :visible-arrow="false"
              width="auto"
              trigger="click">
              <div class="org-members-dropdown-cont">
                <!-- TODO: re-enable button after initial release -->
                <app-text
                  v-if="$can($permissions.MODIFY_USERS_ON_PARTNERSHIPS)"
                  class="hover:tw-cursor-not-allowed">
                  Manage Partnership <span class="tag-soon">Coming Soon</span>
                </app-text>
                <app-text
                  v-if="!isCurrentUser(member) && $can($permissions.DELETE_USER_FROM_ORGANIZATION)"
                  @click="toggleDeleteMemberModal(member)">
                  Remove
                </app-text>
              </div>
              <ellipsis-icon slot="reference" class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400 hover:tw-cursor-pointer" />
            </el-popover>
          </portal>
        </app-card>
      </template>
      <div v-for="i in 3" v-else-if="loading" :key="`partnership-list-${i}`">
        <skeleton-box class="tw-h-layout-4 tw-mb-space-2 tw-w-full" />
      </div>
      <empty-org-settings-state v-else :is-search="!!searchValue" label="members" />
    </div>
    <new-user-modal
      v-if="isNewUserModalOpen"
      :available-roles="availableRoles"
      :visible.sync="isNewUserModalOpen"
      @cancel="isNewUserModalOpen = false"
      @successful-new-member="addNewMember" />
    <remove-member-modal
      v-if="isRemoveModalOpen"
      :loading="loading"
      :visible.sync="isRemoveModalOpen"
      @cancel="isRemoveModalOpen = false"
      @delete-member="handleDeleteMember($event)" />
  </div>
</template>

<script>
import AppHeading from '@/components/AppHeading.vue';
import AppText from '@/components/AppText';
import AppCard from '@/components/AppCard';
import AppSelect from '@/components/AppSelect';
import AppProfilePicture from '@/components/AppProfilePicture';
import AppLink from '@/components/AppLink';
import AppInput from '@/components/AppInput';
import SearchIcon from '@/assets/svg/search-icon.svg';
import AppButton from '@/components/AppButton.vue';
import PlusIcon from '@/assets/svg/plus-icon.svg';
import StarIcon from '@/assets/svg/star-icon.svg';
import EllipsisIcon from '@/assets/svg/ellipsis-icon.svg';
import CircleDividerIcon from '@/assets/svg/circle-divider-icon.svg';
import ProfileIcon from '@/assets/svg/profile-icon.svg';
import Breakpoints from '@/mixins/Breakpoints';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import EmptyOrgSettingsState from '@/components/organization-settings/EmptyOrgSettingsState';
import NewUserModal from '@/components/organization-settings/modals/NewUserModal';
import RemoveMemberModal from '@/components/organization-settings/modals/RemoveMemberModal';
import PendingIcon from '@/assets/svg/pending-icon.svg';

export default {
  name: 'OrganizationMembers',
  components: {
    AppHeading,
    AppCard,
    AppProfilePicture,
    AppText,
    AppLink,
    AppInput,
    SearchIcon,
    SkeletonBox,
    PlusIcon,
    AppButton,
    AppSelect,
    StarIcon,
    EmptyOrgSettingsState,
    EllipsisIcon,
    CircleDividerIcon,
    ProfileIcon,
    NewUserModal,
    RemoveMemberModal,
    PendingIcon
  },
  mixins: [Breakpoints([768])],
  data() {
    return {
      ownerData: {},
      searchValue: '',
      loading: false,
      orgMembers: [],
      isNewUserModalOpen: false,
      isRemoveModalOpen: false,
      availableRoles: [],
      memberBeingDeleted: null,
      isTransferOwnershipButtonDisabled: true // TODO: temporarily disabled for initial release. remove once functionality is built in.
    };
  },
  computed: {
    smUp() {
      return this.windowBreakpoint >= 768;
    },
    currentUserId() {
      return this.$store.getters.user.user_id;
    }
  },
  watch: {
    searchValue: {
      immediate: true,
      handler(val) {
        this.getUsers(val);
      }
    }
  },
  created() {
    this.fetchUserRoles();
  },
  methods: {
    isCurrentUser(member) {
      return member.id === this.currentUserId;
    },
    async getUsers(search = '') {
      try {
        this.loading = true;
        const response = await this.$api.organization.fetchUsers({ q: search });
        this.ownerData = response.find((item) => (item.role.name).toLowerCase() === 'owner') || this.ownerData;
        this.orgMembers = response.filter((item) => (item.role.name).toLowerCase() !== 'owner' && item.user_org_status !== 'inactive');
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    handleManageModal(member) {
      this.$emit('manage-modal');
      member.showManagePartnership = false;
    },
    toggleDeleteMemberModal(member) {
      this.isRemoveModalOpen = !this.isRemoveModalOpen;
      this.memberBeingDeleted = member.id;
    },
    async handleDeleteMember() {
      try {
        this.loading = true;
        await this.$api.organization.deleteUser({
          userId: this.memberBeingDeleted,
          storeAction: 'deleteUser'
        });
      } catch (e) {
        if (this.$api.isCancel(e)) return;
        if (this.$api.hasErrors(e)) this.error = e.repsonse.data._meta.errors[0];
      } finally {
        this.getUsers();
        this.isRemoveModalOpen = false;
        this.loading = false;
      }
    },
    handleTransferOwnership() {
      if (this.isTransferOwnershipButtonDisabled) return;
      this.$emit('transfer-ownership');
    },
    addNewMember() {
      this.isNewUserModalOpen = !this.isNewUserModalOpen;
      this.getUsers();
    },
    async fetchUserRoles() {
      try {
        this.loading = true;
        const roles = await this.$api.organization.fetchUserRoles();
        // Condition will change after initial release
        this.availableRoles = roles.filter(role => role.is_admin);
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.organization-members {
  .app-card__header {
    @apply tw-flex;
  }

  .member-list {
    .app-card__header {
      @apply
        sm:tw-w-1/5 tw-w-full
        tw-mb-space-2 tw-pb-space-2
        sm:tw-mb-0 sm:tw-border-0 sm:tw-pb-0;
    }

    .app-card__body {
      @apply
        tw-pb-space-2 sm:tw-mb-0 sm:tw-pb-0;
    }

    .app-card__footer {
      @apply sm:tw-right-space-3;
    }
  }
}

.org-member-dropdown-filter {
  .el-select-dropdown__item {
    @apply tw-pt-space-1 tw-pb-space-2 tw-h-auto;
  }
}

.org-members-permissons-popper {
  @apply tw-p-0 tw-border-none tw-rounded-none;

  &[x-placement^="bottom"] {
    @apply tw-mt-space-1;
  }

  &[x-placement^="top"] {
    @apply tw-mb-space-1;
  }

  .org-members-dropdown-cont {
    @apply tw-flex tw-flex-col;

    .tag-soon {
      @apply tw-bg-jb-orange tw-px-space-2 tw-pt-space-1 tw-pb-0.5 tw-mx-space-1 tw-align-middle tw-rounded-full
        tw-text-3xs tw-uppercase tw-font-bold tw-text-white;
    }

    .app-text {
      @apply tw-text-jb-grey-700 tw-cursor-pointer tw-p-space-2 hover:tw-bg-grey-hover tw-font-medium hover:tw-font-semibold;
    }
  }
}
</style>
