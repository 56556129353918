<template>
  <el-select
    ref="appselect"
    :key="_uid"
    class="app-select"
    autocomplete="sthap"
    size="mini"
    v-bind="$attrs"
    :popper-append-to-body="popperAppendToBodyComputed"
    :popper-class="popperClassComputed"
    v-on="$listeners">
    <template #prefix>
      <slot name="prefix" />
    </template>
    <template #empty>
      <slot name="empty" />
    </template>
    <el-option
      v-for="item in data"
      :key="item[optionValueKey]"
      :label="item[optionLabelKey]"
      class="tw-flex tw-items-center"
      :value="item[optionValueKey]">
      <slot :item="item" name="option" />
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'AppSelect',
  props: {
    data: {
      type: [Array],
      required: true
    },
    optionLabelKey: {
      type: String,
      default: 'name'
    },
    optionValueKey: {
      type: String,
      default: 'id'
    },
    appendedToDiv: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    popperClassComputed() {
      let baseClass = 'app-select-popover';
      if (this.appendedToDiv) baseClass += ' appended-to-div';
      if (this.$attrs['popper-class']) baseClass += ` ${this.$attrs['popper-class']}`;
      return baseClass;
    },
    popperAppendToBodyComputed() {
      return this.$attrs['popper-append-to-body'] || !this.appendedToDiv;
    }
  },
  methods: {
    valueChange(val) {
      this.$emit('change', val);
    }
  }
};
</script>

<style lang="scss">
.app-select {
  .el-input {
    @include el-input;
  }

  .el-input .el-input__inner {
    @apply tw-pl-space-2 focus:tw-pl-space-2;
  }

  .appended-to-div {
    @apply tw-relative tw-top-auto tw-left-auto #{!important};
  }
}
</style>
