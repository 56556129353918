<template>
  <app-modal
    width="35%"
    class="new-user-modal"
    v-bind="$attrs"
    :loading="loading"
    title="Invite New Member"
    :destroy-on-close="true"
    :show-close="true"
    v-on="$listeners"
    @confirm="addNewUser">
    <template #message>
      <app-text class="tw-text-jb-grey-700 font-semibold">
        Add a new member to your organization by filling the fields below.
      </app-text>
      <div class="tw-mb-space-2 tw-mt-space-4">
        <app-form :model="newUserForm" @submit.prevent.native>
          <app-form-input
            v-model="newUserForm.email"
            prop="input"
            type="email"
            placeholder="Email"
            weight="medium"
            :error="error"
            class="tw-text-jb-grey-700 tw-mb-space-4 tw-text-xs"
            label="Email Address"
            @change="error = ''" />
        </app-form>

        <div class="tw-mb-space-4">
          <app-text type="div" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-1 tw-text-xs">
            Role
          </app-text>
          <app-select
            v-model="availableRoles[0].name"
            disabled
            placeholder="Choose Role"
            class="tw-block"
            :data="availableRoles">
            <template slot="option" slot-scope="row">
              <div class="tw-flex tw-items-center">
                {{ row.item.name }}
              </div>
            </template>
          </app-select>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/AppModal';
import AppSelect from '@/components/AppSelect';
import AppText from '@/components/AppText';
import AppForm from '@/components/AppForm';
import AppFormInput from '@/components/AppFormInput';

export default {
  name: 'NewUserModal',
  components: { AppModal, AppSelect, AppText, AppForm, AppFormInput },
  props: {
    availableRoles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      error: '',
      newUserForm: {
        email: '',
        role: this.availableRoles[0].id
      }
    };
  },
  methods: {
    async addNewUser(event) {
      try {
        if (!this.newUserForm.email || !this.newUserForm.role) return;
        this.loading = true;
        const { role: role_id, email } = this.newUserForm;
        await this.$api.organization.addNewUser({
          storeAction: 'addNewUser',
          params: { role_id, email }
        });
        this.$emit('successful-new-member');
      } catch (e) {
        if (this.$api.isCancel(e)) return;
        this.error = e.response.data._meta.errors[0];
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.new-user-modal {
  .el-form-item__content {
    .app-text {
      @apply tw-text-xs tw-text-jb-grey-700 tw-font-medium;
    }
  }
}

</style>
